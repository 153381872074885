<template>
  <header>
    <div class="header-container">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Martyns Qualidade Logo" />
      </div>
      <button class="menu-toggle" @click="toggleMenu">☰</button>
      <nav :class="{ open: menuOpen }">
        <a
          href="#home"
          :class="{ active: currentSection === 'home' }"
          @click="scrollToSection('home')"
          >Home</a
        >
        <a
          href="#servicos"
          :class="{ active: currentSection === 'servicos' }"
          @click="scrollToSection('servicos')"
          >Serviços</a
        >
        <a
          href="#sobre-nos"
          :class="{ active: currentSection === 'sobre-nos' }"
          @click="scrollToSection('sobre-nos')"
          >Sobre Nós</a
        >
        <a
          href="#contato"
          :class="{ active: currentSection === 'contato' }"
          @click="scrollToSection('contato')"
          >Contato</a
        >
      </nav>
      <a
        href="https://wa.me/5527998969507?text=Gostaria%20de%20fazer%20um%20or%C3%A7amento"
        class="cta"
        target="_blank"
        rel="noopener noreferrer"
        >Faça um Orçamento</a
      >
    </div>
    <div v-if="menuOpen" class="dropdown">
      <nav>
        <a
          href="#home"
          :class="{ active: currentSection === 'home' }"
          @click="scrollToSection('home')"
          >Home</a
        >
        <a
          href="#servicos"
          :class="{ active: currentSection === 'servicos' }"
          @click="scrollToSection('servicos')"
          >Serviços</a
        >
        <a
          href="#sobre-nos"
          :class="{ active: currentSection === 'sobre-nos' }"
          @click="scrollToSection('sobre-nos')"
          >Sobre Nós</a
        >
        <a
          href="#contato"
          :class="{ active: currentSection === 'contato' }"
          @click="scrollToSection('contato')"
          >Contato</a
        >
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      currentSection: "home",
      menuOpen: false,
    };
  },
  methods: {
    scrollToSection(section) {
      this.currentSection = section;
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      this.menuOpen = false; // Close the menu after navigation
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

body {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #333;
}

header {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding: 10px 20px;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 50px;
  margin-right: 10px;
}

.logo span {
  font-size: 14px;
  color: #333;
}

nav {
  display: flex;
  gap: 20px;
  justify-content: center;
}

nav a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  position: relative;
  padding-bottom: 5px;
}

nav a.active {
  color: #4e6fe0;
  font-weight: bold;
}

.cta {
  margin-right: 5vw;
  background-color: #4e6fe0;
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  text-decoration: none;
  font-size: 16px;
  text-align: center;
}

.cta:hover {
  background-color: #4e6fe0;
}

.menu-toggle {
  color: #4e6fe0;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 30px;
  display: none;
}

.dropdown {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  background-color: white;
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  z-index: 1000;
}

@media (max-width: 900px) {
  nav {
    display: none;
  }

  nav.open {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 82px;
    left: 0;
    transition: max-height 0.3s ease-in-out;
    max-height: 300px;
    overflow: hidden;
    border-top: 1px solid #ddd;
  }

  nav a {
  width: 100%;
  padding: 10px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


  .menu-toggle {
    display: block;
    margin-left: auto;
  }

  .cta {
    display: none;
  }

  .logo img {
    width: 280px;
    margin: 10px 10px 0px 10px;
}

  header{
    padding:0px 0px;
  }
}
</style>
