<template>
  <section id="servicos" class="section">
    <h1>Serviços</h1>
    <div class="box-container">
      <Carousel v-bind="settings" :breakpoints="breakpoints">
        <Slide v-for="(service, index) in services" :key="index">
          <BoxService
            :imageSrc="service.imageSrc"
            :title="service.title"
            :text="service.text"
          />
        </Slide>
        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </div>
  </section>
</template>

<script>
import BoxService from "./BoxService.vue";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "AppAbout",
  components: {
    BoxService,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        700: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        1200: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        1500: {
          itemsToShow: 4,
          snapAlign: "start",
        },
        1600:{
          itemsToShow: 5,
          snapAlign: "start",
        }
      },
      services: [
        {
          imageSrc: require('@/assets/inspec.jpeg'),
          title: "Inspeção de Soldas",
          text: "Fornecimento de mão de obra qualificada de inspetor de soldagem."
        },
        {
          imageSrc: require('@/assets/qualifica.png'),
          title: "Qualificação de Soldadores",
          text: "Qualificação de Soldadores pela norma ASME IX, AWS D1.1, dentre outras."
        },
        {
          imageSrc: require('@/assets/procedimento.jpg'),
          title: "Qualificação de Procedimentos",
          text: "Qualificamos seu Procedimento de Soldagem de acordo com a Norma especificada."
        },
        {
          imageSrc: require('@/assets/terceir.jpeg'),
          title: "Terceirização de Mão de Obra",
          text: "Oferecemos profissionais especializados para atender demandas temporárias ou permanentes."
        },
        {
          imageSrc: require('@/assets/tinta.jpeg'),
          title: "Inspeção de Pintura",
          text: "Verificamos a qualidade e aderência das pinturas industriais."
        },
        {
          imageSrc: require('@/assets/ultrasom.jpeg'),
          title: "Inspeção por Ultrassom Phased Array",
          text: "Utilizamos tecnologia de ultrassom avançada para detectar descontinuidades internas."
        },
        {
          imageSrc: require('@/assets/liquido.jpeg'),
          title: "Inspeção por Líquidos Penetrantes",
          text: "Aplicamos métodos de líquidos penetrantes para identificar fissuras e defeitos superficiais."
        },
        {
          imageSrc: require('@/assets/particula.jpg'),
          title: "Inspeção por Partículas Magnéticas",
          text: "Realizamos inspeções magnéticas para detectar descontinuidades em materiais ferromagnéticos."
        },
        {
          imageSrc: require('@/assets/irata.png'),
          title: "Inspetor IRATA",
          text: "Realizamos inspeções em locais de difícil acesso utilizando técnicas de acesso por cordas, garantindo a segurança e a qualidade dos serviços de soldagem."
        }
      ],
    };
  },
};
</script>

<style scoped>
h1 {
  font-size: 32px;
  color: #4e6fe0;
  font-weight: 900;
  text-align: center;
}

.section {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 110px;
}

.box-container {
  justify-content: center;
  margin-top: 110px;
  width: 80vw;
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

.carousel__prev {
  color: white !important;
}

@media (max-width: 1320px) {
  .section {
    height: 100%;
    margin-bottom: 100px;
  }
  .box-container {
    grid-template-columns: repeat(3, 4fr);
  }
}

@media (max-width: 1030px) {
  .box-container {
    grid-template-columns: repeat(2, 4fr);
  }
}

@media (max-width: 900px) {
  .section {
    width: 100px;
    height: 100%;
    padding-top: 0px;
  }

  .box-container {
    grid-template-columns: repeat(2, 4fr);
  }
}

@media (max-width: 670px) {
  h2 {
    font-size: 12px;
  }

  h1 {
    font-size: 18px;
  }
  .box-container {
    grid-template-columns: repeat(1, 4fr);
  }
}

@media (max-height: 800px) {
  .section {
    height: 120vh;
  }
}

@media (max-height: 650px) {
  .section {
    height: 150vh;
  }
}
</style>
